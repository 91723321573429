// this is the logger for the browser
// import pino from 'pino';
const pino = require('pino');

const COLOR = {
	GREEN: '\x1b[32m',
	RED: '\x1b[31m',
	WHITE: '\x1b[37m',
	YELLOW: '\x1b[33m',
	CYAN: '\x1b[36m',
};

const LEVEL_COLORS = {
	FATAL: COLOR.RED,
	ERROR: COLOR.RED,
	WARN: COLOR.YELLOW,
	INFO: COLOR.GREEN,
	DEBUG: COLOR.GREEN,
	TRACE: COLOR.GREEN,
};

const levelFormatter = (_: unknown, number: number): { severity: string } => {
	let severity;
	switch (number) {
		case 10:
		case 20: {
			severity = 'DEBUG';
			break;
		}

		case 30: {
			severity = 'INFO';
			break;
		}

		case 40: {
			severity = 'WARNING';
			break;
		}

		case 50: {
			severity = 'ERROR';
			break;
		}

		case 60: {
			severity = 'CRITICAL';
			break;
		}

		default: {
			severity = 'DEFAULT';
			break;
		}
	}

	return { severity };
};

const pinoConfig: any = {
	browser: {
		// eslint-disable-next-line max-len
		// Reference: https://stackoverflow.com/questions/79271735/how-can-i-format-pino-logger-output-in-the-browser-console
		write: (logObj: Record<string, string>) => {
			const {
				level, msg, group, time,
			} = logObj;

			const levelUppercased = levelFormatter(undefined, parseInt(level, 10)).severity;

			const timeFormatted = new Date(time).toISOString();

			const LEVEL_COLOR = LEVEL_COLORS[levelUppercased as keyof typeof LEVEL_COLORS];

			console.log(
				`[${timeFormatted}] ${LEVEL_COLOR}${levelUppercased} ${COLOR.CYAN}${typeof group !== 'undefined' ? `[${group}] ` : ''}${msg} ${COLOR.WHITE}`,
			);
		},
	},
	level: process.env.PINO_LOG_LEVEL || 'info',
	...(process.env['NODE_ENV'] === 'production') && {
		formatters: {
			level: levelFormatter,
		},
	},
	...process.env['NODE_ENV'].includes('dev') && {
		transport: {
			target: 'pino-pretty',
			options: { colorize: true, translateTime: true },
		},
	},
};

let loggerToExport: any;

declare global {
	// eslint-disable-next-line vars-on-top, no-var
	var rnbLogger: any;
}

if (typeof window === 'undefined') {
	if (typeof globalThis.rnbLogger === 'undefined') {
		globalThis.rnbLogger = pino(pinoConfig);
	}
	loggerToExport = globalThis.rnbLogger;
} else {
	loggerToExport = pino(pinoConfig);
}

const logger = loggerToExport;

export const isLogLevelEnabled = (level: string) => {
	if (typeof loggerToExport.isLevelEnabled !== 'undefined') {
		return loggerToExport.isLevelEnabled(level);
	}

	// fallback to handle a bug with pino in the browser
	return level.toLowerCase() === loggerToExport.level;
};

export { logger };

export const log = (msg: string) => logger.info(msg);
export default logger;
