import {
	action, autorun, computed, intercept, observable, makeObservable,
} from 'mobx';

import { isString } from '~/util/isString';
import { isNumber } from '~/util/isNumber';
import { noop } from '~/util/noop';

export class MagicOverlayModel {
	get autoScrollSpinnerIntoView() {
		if (this.disableAutoScroll) {
			return noop;
		}
		return autorun(() => {
			if (this.scrollSpinnerIntoView !== false) {
				window.scrollTo(0, this.scrollSpinnerIntoView);
			}
		}, { name: 'auto scroll spinner into view' });
	}

	get autoState() {
		return autorun(() => {
			switch (this.state) {
				case this.STATES.LOADING_BUFFER:
					window.clearTimeout(this.spinnerBufferTimeout);
					this.spinnerBufferTimeout = window.setTimeout(() => {
						this.setState(this.STATES.LOADING);
						window.clearTimeout(this.messageBufferTimeout);
						this.messageBufferTimeout = window.setTimeout(() => {
							this.setState(this.STATES.LONG_LOADING);
						}, this.totalMessageWait);
					}, this.totalSpinnerWait);
					break;
				case this.STATES.LOADING:
					window.clearTimeout(this.spinnerDisplayTimeout);
					this.spinnerDisplayTimeout = window.setTimeout(() => {
						this.loadingPromise = this.loadingPromise || Promise.resolve();
						this.loadingPromise
							.then(resp => resp)
							.catch(resp => resp)
							.then(() => this.loadingPromiseCallback('spinnerDisplayTimeout'));
					}, this.totalMinSpinnerDuration);
					break;
				case this.STATES.LONG_LOADING:
					window.clearTimeout(this.messageDisplayTimeout);

					this.messageDisplayTimeout = window.setTimeout(() => {
						this.loadingPromise = this.loadingPromise || Promise.resolve();
						this.loadingPromise
							.then(resp => resp)
							.catch(resp => resp)
							.then(() => this.loadingPromiseCallback('messageDisplayTimeout'));
					}, this.minMessageDuration);
					break;
				default:
					break;
			}
		});
	}

	clearBufferTimeouts() {
		window.clearTimeout(this.spinnerBufferTimeout);
		window.clearTimeout(this.messageBufferTimeout);
	}

	clearDisplayTimeouts() {
		window.clearTimeout(this.spinnerDisplayTimeout);
		window.clearTimeout(this.messageDisplayTimeout);
	}

	clearTimeouts() {
		this.clearBufferTimeouts();
		this.clearDisplayTimeouts();
	}

	containerElem = null;

	setContainerElem(elem) {
		this.containerElem = elem;
	}

	containerHeight = 0;

	get containerHeightInterceptor() {
		return intercept(this, 'containerHeight', (change) => {
			if (typeof change.object.value === 'undefined') {
				return change;
			}
			if (this.lockContainerHeight) {
				console.log('container height was blocked from changing due to interceptor');
				return null;
			}
			return change;
		});
	}

	get containerCenterY() {
		return this.containerHeight / 2;
	}

	defaultConfig = {
		containerElem: null,
		disableAutoScroll: false,
		lockContainerHeight: false,
		manualMode: false,
		message: true,
		messageClassName: '',
		messageWait: 250,
		minMessageDuration: 1500,
		minSpinnerDuration: 1500,
		position: 'STICKY',
		retainConfigOnReset: false,
		spinnerClassName: '',
		spinnerColor: '#000',
		spinnerWait: 250,
		state: 'IDLE',
		transitionDuration: 200,
		useOpaqueFrosty: false,
		useOverlay: true,
		useSpinner: true,
	};

	disableAutoScroll = false;

	disposers = undefined;

	get isIdle() {
		return this.state === this.STATES.IDLE;
	}

	get isLoading() {
		return this.state === this.STATES.LOADING;
	}

	get isPositionGlobal() {
		return this.position === 'GLOBAL';
	}

	// If position is "STICKY", the position of the spinner will calculate itself only once if it has not been defined.
	get isPositionSticky() {
		return (
			this.position === 'STICKY'
			|| typeof this.position === 'undefined'
			|| this.position === null
			|| (!isNumber(this.position) && !this.position)
		);
	}

	loadingPromise = undefined;

	loadingPromiseCallback(context = null) {
		if (this.manualMode) {
			return;
		}
		if (context === 'spinnerDisplayTimeout' && this.state === this.STATES.LOADING) {
			this.reset();
		} else if (context === 'messageDisplayTimeout') {
			this.reset();
		} else if (this.state === this.STATES.LOADING_BUFFER) {
			this.reset();
		}
	}

	lockContainerHeight = false;

	// Set to true if you want to control when loading starts/stops instead of Promise based.
	manualMode = false;

	messageClassName = '';

	message = true;

	messageBufferTimeout = undefined;

	messageDisplayTimeout = undefined;

	messageElem = undefined;

	setMessageElem(elem) {
		this.messageElem = elem;
	}

	get messageHeight() {
		return this.messageElem?.offsetHeight || 0;
	}

	get messagePosY() {
		if (this.useSpinner && this.useMessage) {
			if (isString(this.spinnerPosY)) {
				return `calc(${this.spinnerPosY} + ${this.spinnerHeight / 2}px)`;
			}
			return this.spinnerPosY + (this.spinnerHeight / 2);
		}
		if (!this.useSpinner && this.useMessage) {
			if (this.isPositionSticky) {
				return this.containerCenterY - (this.messageHeight / 2);
			}
			if (typeof this.position !== 'undefined') {
				return this.position;
			}
		}
		return null;
	}

	get messageCssPosY() {
		if (isString(this.messagePosY)) {
			return this.messagePosY;
		}
		if (isNumber(this.messagePosY)) {
			return `${this.messagePosY}px`;
		}
		return null;
	}

	messageWait = 250;

	minMessageDuration = 1500;

	minSpinnerDuration = 1500;

	reset() {
		// These values are based on the CSS animation.
		const transitionSpeed = 200;
		const transitionDelay = 500;

		if (!this.retainConfigOnReset) {
			setTimeout(() => {
				const {
					containerElem,
					state,
					messageClassName,
					spinnerClassName,
					message,
					...defaults
				} = this.defaultConfig;

				// mobx strict mode
				this.setContainerElem(containerElem);
				this.setMessageClassName(messageClassName);
				this.setMessage(message);
				this.setSpinnerClassName(spinnerClassName);
				this.setState(state);

				Object.assign(this, defaults);
			}, transitionDelay + transitionSpeed);
		}
		this.setState(this.STATES.IDLE);
		this.loadingPromise = null;
		this.clearTimeouts();
	}

	retainConfigOnReset = false;

	rootElem = undefined;

	get scrollSpinnerIntoView() {
		if (this.disableAutoScroll) {
			return false;
		}
		const containerPosY = Math.abs(this.containerElem?.getBoundingClientRect?.()?.y || 0);
		const minMargin = this.spinnerHeight + this.messageHeight;
		const minHeight = this.spinnerHeight + this.messageHeight;
		const totalHeightTolerance = minMargin + minHeight;

		if (
			this.containerHeight >= totalHeightTolerance
			&& (this.containerHeight - containerPosY) <= totalHeightTolerance
		) {
			return this.spinnerPosY - (window.scrollY - containerPosY);
		}
		return false;
	}

	get shouldShowMessage() {
		return (
			this.useMessage
			&& this.state === this.STATES.LONG_LOADING
		);
	}

	get shouldShowOverlay() {
		return (
			this.state
			&& this.state !== this.STATES.IDLE
			&& this.useOverlay
		);
	}

	get shouldShowSpinner() {
		return (
			this.useSpinner
			&& (
				this.state === this.STATES.LOADING
				|| this.state === this.STATES.LONG_LOADING
			)
		);
	}

	spinnerBufferTimeout = undefined;

	spinnerDisplayTimeout = undefined;

	spinnerColor = '#000';

	spinnerElem = undefined;

	setMessage(message) {
		this.message = message;
	}

	setSpinnerElem(elem) {
		this.spinnerElem = elem;
	}

	get spinnerHeight() {
		// Sensible default to 45px here in case we run into rendering timing issues. For all intents and purposes, the
		// spinner is not going to change its size. But if it does, it will be deliberate and we should update the
		// default anyway.
		return this.spinnerElem?.offsetHeight || 45;
	}

	get spinnerPosY() {
		const containerPosY = Math.abs(this.containerElem?.getBoundingClientRect?.()?.y || 0);
		const minMargin = this.spinnerHeight + this.messageHeight;
		const minHeight = this.spinnerHeight + this.messageHeight;
		const totalHeightTolerance = minMargin + minHeight;
		let result = 0;

		if (this.isPositionGlobal) {
			return '50%';
		}
		if (this.isPositionSticky) {
			if (this.useSpinner && this.useMessage) {
				result = this.containerCenterY - (this.messageHeight / 2);
			} else if (this.useSpinner && !this.useMessage) {
				result = this.containerCenterY;
			}
		} else if (typeof this.position !== 'undefined') {
			return this.position;
		}
		if (
			this.containerHeight >= totalHeightTolerance
			&& (this.containerHeight - containerPosY) <= totalHeightTolerance
		) {
			result = this.containerHeight - totalHeightTolerance - (window.scrollY - containerPosY);
		}
		return result;
	}

	get spinnerCssPosY() {
		if (isString(this.spinnerPosY)) {
			return this.spinnerPosY;
		}
		if (isNumber(this.spinnerPosY)) {
			return `${this.spinnerPosY}px`;
		}
		return null;
	}

	spinnerClassName = '';

	setSpinnerClassName(className) {
		this.spinnerClassName = className;
	}

	setMessageClassName(className) {
		this.messageClassName = className;
	}

	spinnerWait = 250;

	state = 'IDLE';

	setState(newState) {
		this.state = newState;
	}

	STATES = {
		IDLE: 'IDLE',
		LOADING_BUFFER: 'LOADING_BUFFER',
		LOADING: 'LOADING',
		LONG_LOADING: 'LONG_LOADING',
	};

	get stateInterceptor() {
		return intercept(this, 'state', (change) => {
			if (!this.#validStates.includes(change.newValue)) {
				console.error(
					`Attempted to change state to an invalid value of ${change.newValue}.
					Possible values are: ${this.#validStates.join(', ')}`,
				);
				return null;
			}
			return change;
		});
	}

	get totalMessageWait() {
		return this.messageWait + this.transitionDuration;
	}

	get totalMinSpinnerDuration() {
		return this.minSpinnerDuration + this.transitionDuration;
	}

	get totalSpinnerWait() {
		return this.spinnerWait + this.transitionDuration;
	}

	transitionDuration = 200;

	get useMessage() {
		return Boolean(this.message);
	}

	useOverlay = true;

	useSpinner = true;

	/*
		IDLE = Nothing is loading or displaying.
		LOADING = Spinner is showing.
		LONG_LOADING = Spinner is showing along with a message.
	 */
	#validStates = [
		this.STATES.IDLE,
		this.STATES.LOADING_BUFFER,
		this.STATES.LOADING,
		this.STATES.LONG_LOADING,
	];

	withOnlyFrosty() {
		this.message = false;
		this.useSpinner = false;
	}

	constructor(options = {}) {
		makeObservable(this, {
			clearBufferTimeouts: action.bound,
			clearDisplayTimeouts: action.bound,
			clearTimeouts: action.bound,
			containerElem: observable.ref,
			containerHeight: observable,
			containerCenterY: computed,
			disableAutoScroll: observable,
			isIdle: computed,
			isPositionGlobal: computed,
			isPositionSticky: computed,
			loadingPromiseCallback: action.bound,
			message: observable,
			messageClassName: observable,
			messageElem: observable.ref,
			messageHeight: computed,
			messagePosY: computed,
			messageCssPosY: computed,
			reset: action.bound,
			retainConfigOnReset: observable,
			rootElem: observable.ref,
			setMessage: action.bound,
			setSpinnerClassName: action.bound,
			setMessageClassName: action.bound,
			setContainerElem: action.bound,
			setMessageElem: action.bound,
			setSpinnerElem: action.bound,
			scrollSpinnerIntoView: computed,
			shouldShowMessage: computed,
			shouldShowOverlay: computed,
			shouldShowSpinner: computed,
			spinnerClassName: observable,
			spinnerElem: observable.ref,
			spinnerHeight: computed,
			spinnerPosY: computed,
			spinnerCssPosY: computed,
			state: observable,
			setState: action.bound,
			useMessage: computed,
		});

		Object.assign(this, this.defaultConfig, options);
	}
}
