import { computed, observable, action, makeObservable } from 'mobx';

import { GlobalDynamicAnalytics } from './GlobalDynamicAnalytics.model';

import { isEngage } from '~/global/global.constants';
import { FAVORITES_LIST_TYPE } from '~/favorites/Types/Favorites.interface';

class GlobalDynamic {
	links = undefined;

	analytics = undefined;

	cartSummary = undefined;

	customerId = undefined;

	customerGroup = undefined;

	employee = undefined;

	favoritesSummary = undefined;

	gpcSignal = undefined;

	activeFeatureToggles = undefined;

	searchAccessToken = '';

	recommendationsAccessToken = undefined;

	myOpportunity = undefined;

	customerTrackingKey = undefined;

	vimeoAccessToken = '';

	workingWith = {};

	isLoggedIn = false;

	siteMarketingMessages = [];

	siteMessages = [];

	userName = '';

	wishlistSummary = {};

	zipCode = null;

	inHomeDeliveryFeeAmount = undefined;

	guestCustomerStatus = ['Guest', 'Guest Cart'];

	loggedInCustomerStatus = ['Web', 'Sap'];

	sapOnlyCustomerStatus = ['Sap'];

	data = undefined;

	constructor(data = {}) {
		makeObservable(this, {
			data: observable.ref,
			analytics: observable,
			links: observable,
			cartSummary: observable,
			customerGroup: observable,
			employee: observable,
			favoritesSummary: observable,
			gpcSignal: observable,
			activeFeatureToggles: observable,
			searchAccessToken: observable,
			recommendationsAccessToken: observable,
			vimeoAccessToken: observable,
			workingWith: observable,
			isLoggedIn: observable,
			siteMarketingMessages: observable,
			siteMessages: observable,
			userName: observable,
			wishlistSummary: observable,
			inHomeDeliveryFeeAmount: observable,
			update: action.bound,
			activeFeatureToggleClasses: computed,
			addGiftCardToCartLink: computed,
			hasTransZone: computed,
			accountViewsLink: computed,
			cartPageLink: computed,
			currentTransZoneLink: computed,
			cartNumber: computed,
			cartQuantity: computed,
			createAccountLink: computed,
			customerId: observable,
			customerSearchTypesLink: computed,
			customerStatus: computed,
			customerTrackingKey: observable,
			defaultFavoritesListId: computed,
			employeeInitials: computed,
			employeeNumber: computed,
			employeeDisplayName: computed,
			favoritesQuantity: computed,
			favoritesLists: computed,
			isSwatchOnlyCart: computed,
			isCustomerGuest: computed,
			isBusinessCustomer: computed,
			locationSelectorLink: computed,
			loginLink: computed,
			loginPageLink: computed,
			logoutLink: computed,
			openDeliveryGroupsLink: computed,
			openDeliveryGroupsStatusLink: computed,
			myOpportunity: observable,
			proxyCustomerByCartNumberLink: computed,
			proxyCustomerByCustomerIdLink: computed,
			proxyLandingPageLink: computed,
			resumePaymentLink: computed,
			workingWithName: computed,
			storeName: computed,
			wishlistPageLink: computed,
			wishlistQuantity: computed,
			zipCode: observable,
		});

		this.links = new Map();
		this.cartSummary = {};
		this.employee = {};
		this.recommendationsAccessToken = '';
		this.customerTrackingKey = null;
		this.activeFeatureToggles = [];
		this.data = data;
		this.update(data);
	}

	update(data = {}) {
		try {
			this.data = data;
			this.myOpportunity = data.myOpportunity || null;
			this.cartSummary = data.cartSummary || {};
			this.favoritesSummary = data.favoritesSummary || {};
			this.gpcSignal = data.gpcSignal || false;
			this.customerGroup = data.customerGroup || null;
			this.customerId = data.customerId || '';
			this.customerTrackingKey = data.customerTrackingKey || null;
			this.employee = data.employee || {};
			this.isLoggedIn = data.isLoggedIn || false;
			this.searchAccessToken = data.searchAccessToken;
			this.recommendationsAccessToken = data.recommendationsAccessToken;
			this.workingWith = data.workingWith || {};
			this.siteMarketingMessages = data.siteMarketingMessages || [];
			this.siteMessages = data.siteMessages || [];
			this.userName = data.userName || '';
			this.vimeoAccessToken = data.vimeoAccessToken;
			this.wishlistSummary = data.wishlistSummary || {};
			this.inHomeDeliveryFeeAmount = data.inHomeDeliveryFeeAmount || null;
			this.zipCode = data.zipCode || null;

			const {
				_links = {},
				analytics = undefined,
				enabledFeatures = [],
			} = data;

			if (_links) {
				this.links.replace(Object.entries(_links));
			}
			if (analytics) {
				this.analytics = new GlobalDynamicAnalytics(analytics);
			}
			if (enabledFeatures) {
				if (Array.isArray(enabledFeatures)) {
					this.activeFeatureToggles.replace(enabledFeatures);
				} else {
					console.trace('GlobalDynamicModel.update: enabledFeatures is not an array', enabledFeatures);
					this.activeFeatureToggles.replace(Object.entries(enabledFeatures));
				}
			} else {
				this.activeFeatureToggles.clear();
			}
		} catch (error) {
			console.error('Error processing global dynamic data', error);
		}
	}

	get addGiftCardToCartLink() {
		return this.links?.get?.('addGiftCardToCart')?.href;
	}

	get activeFeatureToggleClasses() {
		return this.activeFeatureToggles.map((toggleName) => {
			return `feature_${toggleName}`;
		}).join(' ');
	}

	get allFavoritesLists() {
		return [...this.favoritesLists, ...this.favoritePhotosLists];
	}

	get hasTransZone() {
		return Boolean(this.currentTransZoneLink);
	}

	get accountViewsLink() {
		return this.links?.get?.('accountViews')?.href;
	}

	get cartPageLink() {
		return this.links?.get?.('cartPage')?.href;
	}

	get cartAPILink() {
		return this.links?.get?.('cart')?.href;
	}

	get currentTransZoneLink() {
		return this.links?.get?.('currentTransportationZone')?.href;
	}

	get cartNumber() {
		return this.cartSummary?.shoppingCartNumber || '';
	}

	get cartQuantity() {
		return this.cartSummary?.quantity;
	}

	get createAccountLink() {
		return this.links?.get?.('createAccount')?.href;
	}

	get customerSearchTypesLink() {
		return this.links.get('customerSearchTypes')?.href;
	}

	get isCustomerGuest() {
		return (!isEngage && !this.isLoggedIn) || (this.isEngageGuest);
	}

	get isBusinessCustomer() {
		return this.customerGroup !== null && this.customerGroup === 'BUSINESS';
	}

	// Returns 'Guest' | 'Guest Cart' | 'Web' | 'Sap'
	get customerStatus() {
		return this.workingWith?.source || '';
	}

	get defaultFavoritesListId() {
		return this.favoritesSummary?.defaultListId || null;
	}

	get employeeInitials() {
		return this.employee?.initials || '';
	}

	get employeeNumber() {
		return this.employee?.employeeNumber || '';
	}

	get employeeDisplayName() {
		return this.employee?.displayName || '';
	}

	get favoritesLists() {
		const lists = this.favoritesSummary?.favoritesLists || this.favoritesSummary?.lists || [];
		return lists.map(list => ({
			...list,
			listType: FAVORITES_LIST_TYPE.ITEM,
		}));
	}

	get favoritePhotosLists() {
		if (this.favoritesSummary?.favoritePhotosLists) {
			return this.favoritesSummary.favoritePhotosLists.map(list => ({
				...list,
				listType: FAVORITES_LIST_TYPE.PHOTO,
			}));
		}

		return [];
	}

	get favoritesQuantity() {
		return this.favoritesSummary?.totalItemCount || 0;
	}

	get isSwatchOnlyCart() {
		return (this.cartSummary?.shoppingCartQuantity === 0 && this.cartSummary?.swatchCartQuantity > 0);
	}

	get locationSelectorLink() {
		return this.links?.get?.('locationSelector')?.href;
	}

	get loginLink() {
		return this.links?.get?.('login')?.href;
	}

	get loginPageLink() {
		return this.links?.get?.('loginPage')?.href;
	}

	get logoutLink() {
		return this.links?.get?.('logout')?.href;
	}

	get openDeliveryGroupsLink() {
		return this.links?.get?.('openDeliveryGroups')?.href;
	}

	get openDeliveryGroupsStatusLink() {
		return this.links?.get?.('openDeliveryGroupsStatus')?.href;
	}

	get proxyCustomerByCartNumberLink() {
		return this.links?.get?.('proxyCustomerByCartNumber')?.href;
	}

	get proxyCustomerByCustomerIdLink() {
		return this.links?.get?.('proxyCustomerByCustomerId')?.href;
	}

	get proxyLandingPageLink() {
		return this.links?.get?.('proxyLandingPage')?.href;
	}

	get resumePaymentLink() {
		return this.links?.get?.('resumePayment')?.href;
	}

	get isDaWorkingWithCustomer() {
		return isEngage && this.loggedInCustomerStatus.includes(this.customerStatus);
	}

	get isEngageSAPOnlyCustomer() {
		return isEngage && this.sapOnlyCustomerStatus.includes(this.customerStatus) && typeof this.createAccountLink !== 'undefined';
	}

	get isEngageGuest() {
		return isEngage && this.guestCustomerStatus.includes(this.customerStatus);
	}

	get workingWithName() {
		return this.workingWith?.name || '';
	}

	get sapCustomerNumber() {
		if (this.data.analytics) {
			return this.data.analytics.sapCustomerNumber;
		}
		return null;
	}

	get canCreateOpportunity() {
		return this.myOpportunity?._links?.createNewOpportunity || false;
	}

	get storeName() {
		return this.employee?.storeName || '';
	}

	get wishlistPageLink() {
		return this.links?.get?.('wishlistPage')?.href;
	}

	get wishlistQuantity() {
		return this.wishlistSummary?.quantity || 0;
	}

	get ssrData() {
		const {
			analytics: {
				adobeLaunchIpAddressClassificationType = undefined,
			} = {},
			siteMarketingMessages,
			enabledFeatures = [],
			inHomeDeliveryFeeAmount = 0,
			employee,
			workingWith,
			_links,
		} = this.data;

		return {
			adobeLaunchIpAddressClassificationType,
			siteMarketingMessages,
			enabledFeatures,
			inHomeDeliveryFeeAmount,
			employee,
			workingWith,
			_links,
		};
	}
}

export { GlobalDynamic };
