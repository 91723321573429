import { type FunctionComponent, type PropsWithChildren, ReactNode } from 'react';
import {
	computed, observable, makeObservable, action,
} from 'mobx';

import { isNumber } from '~/util/isNumber';
import { isEngage } from '~/global/global.constants';

export type ModalSettings = {
	absoluteScrollTop: number | string | null
	alignToTopOfWindow: boolean
	accessibleTitle: string
	anchorBottom: boolean
	closeBtnWhiteBackground: boolean
	closeButtonHandler: ((closeModal: () => void) => void) | null
	closeButtonText: string
	closeModalOnOverlayClick: boolean
	closeModalOnEscKey: boolean
	containerClass: string
	content: PropsWithChildren
	dataQa: string | null
	dropShadow: boolean
	flushSides: boolean
	fullBleed: boolean
	height: string
	id: string
	initialScrollTop: number
	isLoading: boolean
	isOpen: boolean
	isTray: boolean
	keepScrollPosition: boolean
	maxWidth: string | number
	onBeforeCloseModal: (() => void) | null
	onOverlayClick: (() => void) | null
	onCloseFocusElement: EventTarget | Element | HTMLElement | null
	onCloseModal: (() => void) | null
	onOpenModal: (() => void) | null
	overflow: string
	reverseCloseButton: boolean
	scrollTop: number
	showCloseButton: boolean
	showHeader: boolean
	showOverlay: boolean
	title: string | ReactNode
	trLinkEventCompName: string | null
	width: number | string
	WrapperComponent: FunctionComponent | null
};

export class MagicModalModel {
	absoluteScrollTop: ModalSettings['absoluteScrollTop'] = null;

	alignToTopOfWindow: ModalSettings['alignToTopOfWindow'] = false;

	accessibleTitle: ModalSettings['accessibleTitle'] = '';

	anchorBottom: ModalSettings['anchorBottom'] = false;

	closeButtonHandler: ModalSettings['closeButtonHandler'] = null;

	closeBtnWhiteBackground: ModalSettings['closeBtnWhiteBackground'] = false;

	closeButtonText: ModalSettings['closeButtonText'] = 'Close';

	closeModalOnOverlayClick: ModalSettings['closeModalOnOverlayClick'] = !isEngage;

	closeModalOnEscKey: ModalSettings['closeModalOnEscKey'] = !isEngage;

	containerClass: ModalSettings['containerClass'] = '';

	content: ModalSettings['content'] = { children: null };

	dropShadow: ModalSettings['dropShadow'] = false;

	flushSides: ModalSettings['flushSides'] = false;

	fullBleed: ModalSettings['fullBleed'] = false;

	height: ModalSettings['height'] = 'auto';

	id: ModalSettings['id'] = '';

	initialScrollTop: ModalSettings['initialScrollTop'] = 0;

	isLoading: ModalSettings['isLoading'] = false;

	isOpen: ModalSettings['isOpen'] = false;

	isTray: ModalSettings['isTray'] = false;

	keepScrollPosition: ModalSettings['keepScrollPosition'] = false;

	maxWidth: ModalSettings['maxWidth'] = 900;

	onBeforeCloseModal: ModalSettings['onBeforeCloseModal'] = null;

	onOverlayClick: ModalSettings['onOverlayClick'] = null;

	onCloseModal: ModalSettings['onCloseModal'] = null;

	onCloseFocusElement: ModalSettings['onCloseFocusElement'] = null;

	onOpenModal: ModalSettings['onOpenModal'] = null;

	overflow: ModalSettings['overflow'] = 'visible';

	reverseCloseButton: ModalSettings['reverseCloseButton'] = false;

	scrollTop: ModalSettings['scrollTop'] = 0;

	showCloseButton: ModalSettings['showCloseButton'] = true;

	showOverlay: ModalSettings['showOverlay'] = true;

	title: ModalSettings['title'] = 'Room & Board';

	trLinkEventCompName: ModalSettings['trLinkEventCompName'] = null;

	width: ModalSettings['width'] = '90vw';

	WrapperComponent: ModalSettings['WrapperComponent'] = null;

	defaults: Omit<ModalSettings, 'content'> = {
		absoluteScrollTop: null,
		alignToTopOfWindow: false,
		accessibleTitle: '',
		anchorBottom: false,
		closeBtnWhiteBackground: false,
		closeButtonHandler: null,
		closeButtonText: 'Close',
		closeModalOnOverlayClick: !isEngage,
		closeModalOnEscKey: !isEngage,
		containerClass: '',
		dataQa: null,
		dropShadow: false,
		flushSides: false,
		fullBleed: false,
		height: 'auto',
		id: '',
		initialScrollTop: 0,
		isLoading: false,
		isOpen: false,
		isTray: false,
		keepScrollPosition: false,
		maxWidth: 900,
		onBeforeCloseModal: null,
		onOverlayClick: null,
		onCloseFocusElement: null,
		onCloseModal: null,
		onOpenModal: null,
		overflow: 'visible',
		reverseCloseButton: false,
		scrollTop: 0,
		showCloseButton: true,
		showHeader: true,
		showOverlay: true,
		title: 'Room & Board',
		trLinkEventCompName: null,
		width: '90vw',
		WrapperComponent: null,
	};

	constructor() {
		makeObservable(this, {
			absoluteScrollTop: observable,
			alignToTopOfWindow: observable,
			accessibleTitle: observable,
			anchorBottom: observable,
			closeButtonHandler: observable,
			closeButtonText: observable,
			closeBtnWhiteBackground: observable,
			closeModalOnOverlayClick: observable,
			closeModalOnEscKey: observable,
			containerClass: observable,
			dropShadow: observable,
			flushSides: observable,
			fullBleed: observable,
			height: observable,
			id: observable,
			initialScrollTop: observable,
			isLoading: observable,
			isOpen: observable,
			isTray: observable,
			keepScrollPosition: observable,
			onBeforeCloseModal: observable,
			onOverlayClick: observable,
			onCloseModal: observable,
			onCloseFocusElement: observable,
			onOpenModal: observable,
			reverseCloseButton: observable,
			scrollTop: observable,
			setIsOpen: action.bound,
			showCloseButton: observable,
			showOverlay: observable,
			title: observable,
			trLinkEventCompName: observable,
			width: observable,
			maxWidth: observable,
			WrapperComponent: observable.ref,
			content: observable.ref,
			heightOutput: computed,
			marginTop: computed,
			widthOutput: computed,
		});

		Object.assign(this, this.defaults);
	}

	get heightOutput(): string {
		if (isNumber(this.height)) {
			return `${this.height}px`;
		}
		return this.height;
	}

	get marginTop(): string {
		const scrollTop = this.scrollTop || 0;

		// deal with integers and append 'px', or take a string for custom 'vh' or 'calc' values
		if (typeof this.absoluteScrollTop === 'number') {
			return `${this.absoluteScrollTop}px`;
		}
		if (this.absoluteScrollTop !== null) {
			return this.absoluteScrollTop;
		}
		if (isEngage) {
			return `${(scrollTop + 100).toString()}px`;
		}
		return `calc(15vh + ${scrollTop}px)`;
	}

	get widthOutput() {
		if (typeof this.width === 'number') {
			return `${this.width}px`;
		}
		return this.width;
	}

	get hasTitle() {
		return Boolean(this.title);
	}

	setIsOpen(isOpen: boolean) {
		this.isOpen = isOpen;
	}
}
