import {
	action, computed, observable, reaction, makeObservable,
} from 'mobx';

import { TopMenuProcessor } from '~/global/top-menu/Processors/MenuProcessor.helper';

import type { TopMenu } from '~/global/top-menu/Models/Menu.model';
import { FAVORITES_LIST_TYPE, IFavoritePhotosListSummary, IFavoritesListSummary } from '~/favorites/Types/Favorites.interface';

export class TopMenuMobileStore {
	featureTogglesModel: any;

	isOpen = false;

	menus: Array<TopMenu> = [];

	menuWasActivated = false;

	menuWasDeactivated = false;

	wasClosed = false;

	wasOpened = false;

	globalDynamic: any = {};

	constructor({
		menus = [],
		globalDynamic = {},
		featureTogglesModel = {},
	} = {}) {
		makeObservable(this, {
			isOpen: observable,
			menuWasActivated: observable,
			menuWasDeactivated: observable,
			wasClosed: observable,
			wasOpened: observable,
			activateMenu: action,
			close: action,
			deactivateMenus: action,
			giveFocus: action,
			open: action,
			favoritesMenu: computed,
		});
		this.featureTogglesModel = featureTogglesModel;
		this.globalDynamic = globalDynamic;

		this.isOpen = false;
		this.menus = menus;
		this.menuWasActivated = false;
		this.menuWasDeactivated = false;
		this.wasClosed = false;
		this.wasOpened = false;
	}

	giveFocus(el: HTMLLinkElement | HTMLElement | null) {
		if (el) {
			el.focus();
		}
	}

	activateMenu(menu: TopMenu) {
		this.menus.forEach((theMenu) => {
			theMenu.isActive = false;
		});

		menu.isActive = true;

		this.menuWasActivated = true;

		this.menuWasDeactivated = false;

		// give focus to the menu's back button
		setTimeout(() => {
			const backButton: HTMLLinkElement | null = document.querySelector(
				`[data-mobile-submenu-back="${(menu.title).toUpperCase()}"]`,
			);
			this.giveFocus(backButton);
		}, 5);
	}

	close() {
		// wait for the slide out animation to complete
		setTimeout(() => {
			this.isOpen = false;
			// give focus to the open menu button
		}, 200);
		this.wasClosed = true;

		this.wasOpened = false;
	}

	deactivateMenus({ nextFocusSelector = null }: {nextFocusSelector: string | null}) {
		// wait for the slide out animation to complete
		setTimeout(() => {
			this.menus.forEach((menu) => {
				menu.isActive = false;
			});
		}, 200);

		setTimeout(() => {
			if (nextFocusSelector) {
				const focusEl: HTMLElement | null = document.querySelector(nextFocusSelector);
				this.giveFocus(focusEl);
			}
		}, 300);

		this.menuWasActivated = false;

		this.menuWasDeactivated = true;
	}

	get primaryMenus() {
		return this.menus.filter((menu: TopMenu) => !menu.isSecondaryMenu);
	}

	get secondaryMenus() {
		return this.menus.filter((menu: TopMenu) => menu.isSecondaryMenu);
	}

	get ideasAdviceMenu() {
		// return null;
		return this.menus.find(({ dataKey }) => dataKey === 'ideas-and-advice');
	}

	getFavoritesListLink(list: IFavoritePhotosListSummary | IFavoritesListSummary) {
		let listLink = '';

		if (list.defaultList && list.listType === FAVORITES_LIST_TYPE.ITEM) {
			listLink = '/favorites/default';
		} else if (list.defaultList && list.listType === FAVORITES_LIST_TYPE.PHOTO) {
			listLink = '/favorites/photos';
		} else {
			listLink = `/favorites/${list.id}`;
		}
		return listLink;
	}

	get favoritesMenu() {
		let favoritesMenuData;
		if (this.globalDynamic.isLoggedIn || (this.featureTogglesModel.isOn('FAVORITE_PHOTOS'))) {
			favoritesMenuData = {
				id: '150',
				url: '/favorites',
				title: 'Lists',
				classes: '',
				hideFull: true,
				hideMobile: true,
				submenuVertical: false,
				dataKey: 'favorites',
				secondaryMenu: true,
				children: [
					{
						id: '1501',
						url: '/#',
						title: 'Lists',
						classes: '',
						dataKey: '',
						hideFull: false,
						columnKey: '',
						hideMobile: false,
						promoUiKey: '',
						children: this.globalDynamic.allFavoritesLists.map((list: any) => ({
							...list,
							title: this.featureTogglesModel.isOn('FAVORITE_PHOTOS') ? `${list.name} (${list.itemCount})` : list.name,
							url: this.getFavoritesListLink(list),
						})),
					},
				],
			};
		} else {
			favoritesMenuData = {
				id: '150',
				url: '/favorites/default',
				title: 'Favorites',
				classes: '',
				hideFull: true,
				hideMobile: true,
				submenuVertical: false,
				dataKey: 'favorites',
				secondaryMenu: true,
			};
		}
		const newFavoritesMenu = TopMenuProcessor.processData([favoritesMenuData])[0];
		const favoritesIndex = this.menus.findIndex(menu => menu.dataKey === 'favorites');
		if (favoritesIndex === -1) {
			this.menus.push(newFavoritesMenu);
		} else {
			this.menus[favoritesIndex] = newFavoritesMenu;
		}
		return newFavoritesMenu;
	}

	open() {
		this.isOpen = true;

		this.wasClosed = false;

		this.wasOpened = true;
	}
}

export const TopMenuMobileStoreFactory = {
	create: ({
		menus = [],
		globalDynamic = {},
		featureTogglesModel = {},
	}: any = {}) => {
		const topMenuMobileStore = new TopMenuMobileStore({ menus, globalDynamic, featureTogglesModel });

		reaction(() => {
			return topMenuMobileStore.wasClosed;
		}, (wasClosed) => {
			if (wasClosed) {
				// after closing we the main menu want to return focus to the open button
				topMenuMobileStore.deactivateMenus({ nextFocusSelector: '[data-button="mobileOpenBtn"]' });
			}
		});

		return topMenuMobileStore;
	},
};
