import { AVAILABILITY_MESSAGE } from '~/util/messaging/availability/availabilityMessage.constants';
import { STATUS_MESSAGE } from '~/util/messaging/line-item-status/statusMessage.constants';

export enum FAVORITES_LIST_TYPE {
	ITEM = 'ITEM',
	PHOTO = 'PHOTO',
}

interface IFavoritesListSummaryCommon {
	id: string
	name: string
	defaultList: boolean
	imageUrl?: string
	itemCount: number
	listType: FAVORITES_LIST_TYPE
	sortOrder: number
}
export interface IFavoritesListSummary extends IFavoritesListSummaryCommon {
	hasDesignPresentation?: boolean
	_links?: {
		favoritesList: {
			href: string
		}
	},
	subtotal?: number,
}

export interface IFavoritePhotosListSummary extends IFavoritesListSummaryCommon {
	_links?: {
		favoritePhotosList: {
			href: string
		}
	},
  }
export interface IFavoritesMoveItemPayload {
	targetGroupId: string;
	targetListId: string;
}

export interface IFavoritesList {
	groups: [IFavoritesListGroup];
	listId: string;
	listName: string;
}

export interface IFavorites {
	daName?: string;
	favoritesList?: IFavoritesList;
	favoritesListSummaries: IFavoritesListSummary[],
	favoritePhotosListSummaries?: IFavoritePhotosListSummary[],
	_links?: {
		favoritesListSummaries: {
			href: string
		}
		addFavoritesList: {
			href: string
		}
	}
}

export interface IReviews {
	count: number
	reviewsAverageOverallRating: number
}

export interface IStatusMessage {
	messageKey: STATUS_MESSAGE
	messageValues?: object
}

export interface IFSMediaViewerImage {
	src: string
	width: number
	height: number
	altText: string
}
export interface IFavoritesListItem {
	id?: string
	altText?: string
	productGroupId?: string
	salesText?: string
	fsMediaViewerImage?: IFSMediaViewerImage
	imageUrl: string
	maxQuantity?: number
	productUrl?: string
	quantity?: number
	reviews?: IReviews
	subtotal?: number
	sortOrder: number
	statusMessage?: IStatusMessage
	unitPrice?: number
	_links?: {
		deleteFavoritePhoto?: {
			href: string
		}
		fakeExpiredProductGroup?: {
			href: string
		}
		updateFavoritesProductGroupQuantity?: {
			href: string
		}
		deleteFavoritesProductGroup?: {
			href: string
		}
		moveFavoritesProductGroupToCart?: {
			href: string
		}
		addFavoritesProductGroupToCart?: {
			href: string
		}
		moveFavoritesProductGroupToList?: {
			href: string
		}
		shopThisRoom?: {
			href: string
		}
	}
}

export interface IFavoritesListGroup {
	groupId: string
	groupName: string
	defaultGroup: boolean
	sortOrder: number
	itemCount: number
	listItems?: IFavoritesListItem[]
	productGroups?: IFavoritesListItem[]
	_links?: {
		favoritesListSummaries?: {
			href: string
		}
		addFavoritesList?: {
			href: string
		}
		getFavoritesProductGroupAvailability?: {
			href: string
		}
		updateFavoritesProductGroupSorts?: {
			href: string
		}
		updatePhotoSorts?: {
			href: string
		},
		favoritesList?: {
			href: string
		}
	}
}

export interface IFavoritesListGroupAvailability {
	productGroupId: string
	message: {
		messageKey: AVAILABILITY_MESSAGE
		messageValues?: {
			availabilityDate?: string
		}
	}
}

export interface IFavoritesListGroupAvailabilityResponse {
	availability: IFavoritesListGroupAvailability[]
}

export interface IExpiredProductGroup {
	productGroupId: string
	salesText: string
	sortOrder: number
}

export interface IDesignPresentation {
	createdByEmployeeName: string,
	dateCreated: string,
	url: string
	_links?: {
		removeDesignPresentation: {
			href: string
		}
	}
}

export interface IFavoritesListDetail {
	designPresentation?: IDesignPresentation
	listId: string
	listLength: number,
	listName: string
	imageUrl?: string
	subtotal: number
	defaultList: boolean
	notes?: string
	productStatus?: string
	sortOrder: number
	itemCount: number
	groups: IFavoritesListGroup[]
	expiredProductGroups: IExpiredProductGroup[]
	_links?: {
		addAllFavoriteProductsToCart?: {
			href: string
		}
		addDesignPresentation?: {
			href: string
		}
		deleteFavoritesList?: {
			href: string
		}
		favoritesList: {
			href: string
		}
		favoritesListShareUrl?: {
			href: string
		}
		moveAllFavoriteProductsToCart?: {
			href: string
		}
		removeExpiredProductGroups?: {
			href: string
		}
		renameFavoritesList?: {
			href: string
		}
		shareFavoritesList?: {
			href: string
		}
		updateFavoritesListNotes: {
			href: string
		}
	}
}

export interface IFavoritesDetail {
	favoritesList: IFavoritesListDetail
	_links?: {
		favoritesListSummaries: {
			href: string
		}
	}
}

export interface IFavoritesPhoto {
	photoId: string
	image: {
		src: string
		width: number
		height: number
		altText: string
	}
	sortOrder: number
	_links?: {
		deleteFavoritePhoto: {
			href: string
		},
		shopThisRoom: {
			href: string
		}
	}
}
export interface IFavoritesPhotoDetail {
	listId: string
	listName: string
	imageUrl?: string
	defaultList: boolean
	sortOrder: number
	itemCount: number
	photos: IFavoritesPhoto[]
	_links?: {
		favoritesList: {
			href: string
		}
		updatePhotoSorts: {
			href: string
		}
	}
}

export type FavoritesDetailResponse = IFavoritesDetail | IFavoritesPhotoDetail;

export function isFavoritesDetail(response: FavoritesDetailResponse): response is IFavoritesDetail {
	return (response as IFavoritesDetail).favoritesList !== undefined;
}

export function isFavoritesPhotoDetail(response: FavoritesDetailResponse): response is IFavoritesPhotoDetail {
	return (response as IFavoritesPhotoDetail).photos !== undefined;
}

export interface IProductAnalytics {
	comparatorString: string
	articleNumber: string
	quantity: number
	unitPrice: number
	name: string
	imageUrl: string
	productUrl: string
	productStatus?: string
	stockedStatus: string
	unattachedOttoman: boolean
	urlCategory: string
	urlICP?: string
	urlSubcategory: string
	webMessage?: string
}

export interface IVendorAnalytics {
	reflektion: {
		skuKey: string
		quantity: number
		unitPrice: number
	}
	variantId: string
}

export interface IAnalyticsProduct {
	product: IProductAnalytics
	vendor: IVendorAnalytics
}

export interface IAddedProductGroups {
	collectionName: string
	imageUrl: string
	quantity: number
	salesText: string
	shortSalesText: string
	unitPrice: number
}

export interface IAddToFavoritesResponse {
	addedProductGroups: IAddedProductGroups[]
	analytics: {
		addedProducts: IAnalyticsProduct[]
	}
	_links?: {
		favoritesListSummaries: {
			href: string
		}
	}
}

export interface IAnalyticsCart {
	cartNumber: string
	cartToken: string
	cartType: string
}

export interface IMovedProducts {
	analytics: {
		movedProducts: IAnalyticsProduct[],
	}
}

export interface IFavoritesMoveItemTrackingData {
	trLinkEventCompName: string;
	trLinkEventName: 'move single item to new favorites list';
	trLinkEventType: 'site action';
}

export interface IFavoritesMoveItemToNewListTrackingData extends IFavoritesMoveItemTrackingData {
	trLinkEventCompType: 'modal';
}

export interface IFavoritesMoveItemToExistingListTrackingData extends IFavoritesMoveItemTrackingData {
	trLinkEventCompType: 'favorites';
}

export interface IFavoritesCreateListModalData extends IFavorites {
	favoritesList?: IFavoritesList;
}

export interface IFavoritesAddListData {
	favoritesList?: IFavoritesList;
	_links: {
		favoritesListSummaries: {
			href: string;
		}
	}
}
