import axios from 'axios';
import Cookie from 'js-cookie';
import {
	action, observable,
} from 'mobx';
import { createContext } from 'mobx-keystone';

import { AccountNudgeModel } from '~/global/header/Models/AccountNudge.model';
import { SignInStore } from '~/account/sign-in/Stores/SignIn.store';
import { MagicModalStore } from '~/components/magic-modal/Stores/MagicModal.store';
import { MagicModalModel } from '~/components/magic-modal/Models/MagicModal.model';
import { EmailSignUpFormStoreFactory } from '~/email-signup/Stores/emailSignUpForm.store';
import { emailSignUpSources } from '~/email-signup/emailSignUp.constants';
import { GlobalDynamicFactory } from '~/global/global-dynamic/Stores/GlobalDynamic.store';
import { GlobalStaticFactory } from '~/global/global-static/Stores/GlobalStatic.store';
import { HeaderMobileStoreFactory } from '~/global/header-mobile/Stores/HeaderMobile.store';
import { LinkEventStoreFactory } from '~/tracking/link-event/Stores/LinkEvent.store';
import { SiteMessagingStore } from '~/global/site-messaging/SiteMessaging.store';
import { PageStoreFactory } from '~/global/Stores/Page.store';
import { TopMenuMobileStoreFactory } from '~/global/top-menu/Stores/TopMenuMobile.store';
import { isOnServer } from '~/global/global.constants';
import { BuildInfoModel } from '~/layout/Models/BuildInfo.model';
import { createSiteMarketingMessagingModel } from '~/site-marketing-messaging/Helpers/SiteMarketingMessaging.init';
import { SITE_MARKETING_MESSAGE_NAME_COOKIE } from '~/site-marketing-messaging/SiteMarketingMessaging.constants';
import { FeatureTogglesModel } from '~/util/feature-toggles/Models/FeatureToggles.model';
import { FeatureToggleStoreFactory } from '~/util/feature-toggles/Stores/FeatureToggle.store';
import { noop } from '~/util/noop';
import { redirect } from '~/util/redirect';
import { LiveChatThirdPartyFactory } from '~/util/live-chat/LiveChat.thirdparty';
import { MagicOverlayStoreFactory } from '~/components/magic-overlay/Stores/MagicOverlay.store';
import { topMenuData } from '~/global/top-menu/top-menu.data';
import { TopMenuProcessor } from '~/global/top-menu/Processors/MenuProcessor.helper';
import { OneTrustStore } from '~/account/sign-in/Stores/OneTrust.store';
import { PersonalizationStore } from '~/personalization/Stores/Personalization.store';

let globalModel;

export const accountNudgeCtx = createContext();
export const magicModalCtx = createContext();
export const featureTogglesCtx = createContext();
export const clientSidePageTransitionCtx = createContext();
export const globalDynamicCtx = createContext();

class GlobalModel {
	emailSignUpFormStore;

	globalDynamicStore;

	headerMobileStore;

	magicModal;

	siteMessagingStore;

	pageStore;

	pathname;

	router;

	searchParams;

	signInStore;

	topMenuMobileStore;

	topMenus;

	featureTogglesModel;

	get HREF() {
		const {
			globalDynamicStore: {
				model: {
					isCustomerGuest = true,
				} = {},
			} = {},
		} = this;

		return {
			account: {
				overview: '/account/overview',
				orderHistory: '/account/orders',
				editItemsAndPricing: '/account/orders/{orderId}/edit/items-and-pricing',
				editDeliveryOptions: '/account/orders/{orderId}/edit/delivery-options',
				editDeliveryAddress: '/account/orders/{orderId}/edit/delivery-address',
				addToDelivery: '/account/orders/{orderId}/edit/add-to-delivery/delivery-group/{deliveryGroup}',
				deliveryAddressBook: '/account/delivery-address-book',
				emailPreferences: '/account/email-preferences',
				swatchOrders: '/account/swatch-orders',
				quotes: '/account/quotes',
			},
			cart: '/cart',
			get favorites() {
				if (isCustomerGuest) {
					return '/favorites/default';
				}
				return '/favorites';
			},
		};
	}
}

export const GlobalModelFactory = {
	create: ({
		pageProps,
		pageProps: {
			cookies: ssrCookies,
			disableGlobalDynamicRequest,
			disableGlobalStaticRequest,
			globalDynamicData: ssrGlobalDynamicData,
		} = {},
		pathname = '',
		router,
		searchParams,
	} = {}) => {
		// pageStore needs to be new'd up on every request or navigation event
		const pageStore = PageStoreFactory.create({ pageProps });

		let liveChat = {};
		const clientSidePageTransition = {
			enabled: observable.box(true),
			store: null,
			setEnabled: action((enabled) => {
				clientSidePageTransition.enabled.set(enabled);
			}),
		};

		const cookies = ssrCookies || Cookie.get();

		// create globalModel fresh every time for SSR and the inital client render pass
		// subsequent client render passes will already have globalModel in memory
		if (isOnServer || !globalModel) {
			try {
				globalModel = new GlobalModel();

				const globalStaticStore = GlobalStaticFactory.create(cookies, disableGlobalStaticRequest);

				const { model: globalStaticModel } = globalStaticStore;

				const globalDynamicStore = GlobalDynamicFactory.create(
					cookies,
					globalStaticModel,
					ssrGlobalDynamicData,
					disableGlobalDynamicRequest,
				);

				const {
					model: globalDynamic,
					model: {
						siteMarketingMessages = [],
					},
				} = globalDynamicStore;

				const featureTogglesModel = new FeatureTogglesModel(globalDynamic);

				const linkEventStore = LinkEventStoreFactory.create(featureTogglesModel);

				const accountNudgeModel = new AccountNudgeModel();

				const magicModal = new MagicModalStore(linkEventStore);
				magicModal.model = new MagicModalModel();

				const personalizationStore = new PersonalizationStore({});

				const buildInfoModel = new BuildInfoModel();

				const featureToggleStore = FeatureToggleStoreFactory.create(magicModal, globalStaticModel);

				const siteMarketingMessagingModel = createSiteMarketingMessagingModel({
					defaultMessageName: !isOnServer ? Cookie.get(SITE_MARKETING_MESSAGE_NAME_COOKIE) : undefined,
					isOnCartPage: pathname === '/cart',
					isOnCareersPage: pathname === '/careers',
					paused: !isOnServer && Boolean(Cookie.get(SITE_MARKETING_MESSAGE_NAME_COOKIE)),
					siteMarketingMessagesData: siteMarketingMessages,
				});

				// need to apply globalDynamic to the store afterward because it's volatile non-keystone data
				const siteMessagingStore = new SiteMessagingStore({});
				siteMessagingStore.globalDynamic = globalDynamic;

				const emailSignUpFormStore = EmailSignUpFormStoreFactory.create({
					hideLegend: false,
					hideLabel: true,
					locationId: 'secondary',
					useInlineSubmitButton: true,
					redirectOnSuccess: true,
					source: emailSignUpSources.FOOTER,
					magicSpinnerProps: {
						minHeight: '0px',
						delay: 0,
						isLoading: true,
					},
				});

				const oneTrustStore = new OneTrustStore({});

				const signInStore = SignInStore(() => {
					if (window.location.href.indexOf('sign-in') > -1) {
						const accountUrl = globalModel.HREF.account.overview;
						redirect(accountUrl);
					}
				}, {
					globalDynamicModel: globalDynamic,
					globalDynamicStore,
					HREF: globalModel.HREF,
				}, null, { id: 'login-header' }, oneTrustStore);

				let globalStaticPromise;

				if (disableGlobalStaticRequest || globalStaticStore.hasInitiallyLoaded) {
					globalStaticPromise = new Promise((resolve) => {
						resolve();
					});
				} else {
					globalStaticPromise = globalStaticStore.fetchData();
				}

				globalStaticPromise
					.then(() => {
						if (globalStaticModel.buildInfoLink) {
							const headers = {};

							axios.request({
								url: globalStaticModel.buildInfoLink,
								method: 'get',
								headers,
							}).then((response) => {
								buildInfoModel.update(response.data);
							}, (error) => {
								console.error(error);
							});
						}
					})
					.catch(noop);

				const menus = TopMenuProcessor.processData(topMenuData);
				const topMenuMobileStore = TopMenuMobileStoreFactory.create({
					menus,
					globalDynamic,
					featureTogglesModel,
				});

				Object.assign(globalModel, {
					topMenus: menus,
				});

				const headerMobileStore = HeaderMobileStoreFactory.create({
					globalDynamic,
					siteMarketingMessagingModel,
					topMenuMobileStore,
					HREF: globalModel.HREF,
					featureTogglesModel,
				});

				if (!isOnServer) {
					liveChat = LiveChatThirdPartyFactory.create({ featureTogglesModel });
					clientSidePageTransition.enabled = observable.box(true);
					clientSidePageTransition.store = MagicOverlayStoreFactory.create({
						position: 'GLOBAL',
						manualMode: true,
						retainConfigOnReset: true,
					});
				}

				Object.assign(globalModel, {
					accountNudgeModel,
					clientSidePageTransition,
					globalDynamicStore,
					globalDynamicModel: globalDynamic,
					globalStaticModel,
					globalStaticStore,
					headerMobileStore,
					emailSignUpFormStore,
					pageStore,
					signInStore,
					topMenuMobileStore,
					featureTogglesModel,
					liveChat,
					linkEventStore,
					magicModal,
					featureToggleStore,
					buildInfoModel,
					cookies,
					siteMarketingMessagingModel,
					siteMessagingStore,
					router,
					searchParams,
					personalizationStore,
				});

				accountNudgeCtx.setDefault(accountNudgeModel);
				magicModalCtx.setDefault(magicModal);
				featureTogglesCtx.setDefault(featureTogglesModel);
				clientSidePageTransitionCtx.setDefault(clientSidePageTransition);
				globalDynamicCtx.setDefault(globalDynamicStore);
				return globalModel;
			} catch (error) {
				console.error(error);
			}
		}

		Object.assign(globalModel, {
			pageStore,
			featureTogglesModel: new FeatureTogglesModel(globalModel.globalDynamicModel),
		});

		return globalModel;
	},
};
