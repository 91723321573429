import { type MouseEvent, type PropsWithChildren } from 'react';
import { action, observable, makeObservable } from 'mobx';

import { type LinkEventStore } from '~/tracking/link-event/Stores/LinkEvent.store';
import { type ModalSettings, type MagicModalModel } from '~/components/magic-modal/Models/MagicModal.model';
import { isMobile } from '~/global/global.constants';
import { scrollToTop } from '~/util/scrollToTop';
import { isFocusable } from '~/util/isFocusable';

class MagicModalStore {
	constructor(linkEventStore?: LinkEventStore) {
		makeObservable(this, {
			alterModal: action.bound,
			model: observable.ref,
			closeModal: action.bound,
			openModal: action.bound,
			resetModal: action.bound,
			scrollToTop: action.bound,
			setIsLoading: action.bound,
			getClosestFocusableParent: action.bound
		});
		this.linkEventStore = linkEventStore;
	}

	alterModal(modalSettings: Partial<ModalSettings>) {
		if (!this.model) {
			return;
		}
		Object.assign(this.model, modalSettings);
	}

	linkEventStore?: LinkEventStore;

	model?: MagicModalModel = undefined;

	closeModal() {
		if (!this.model) {
			return;
		}
		if (typeof this.model.onBeforeCloseModal === 'function') {
			this.model.onBeforeCloseModal();
		}

		let focusEl;
		if (typeof this.model.onCloseFocusElement === 'object') {
			focusEl = this.model.onCloseFocusElement;
		} else if (typeof this.model.onCloseFocusElement === 'string') {
			focusEl = document.querySelector(this.model.onCloseFocusElement);
		}

		if (typeof this.model.onCloseModal === 'function') {
			this.model.onCloseModal();
		}

		this.model.setIsOpen(false);

		this.model.alignToTopOfWindow = false;

		if (focusEl) {
			// Get nearest focusable parent if event target is not focusable
			const focusableElement = this.getClosestFocusableParent(focusEl);

			if (focusableElement) {
				setTimeout(() => {
					if (!(focusableElement instanceof HTMLElement)) {
						return;
					}
					focusableElement.focus();
				}, 0);
				// iOS safari doesn't automatically scroll to focused <button> elements, so we call
				// scrollIntoViewIfNeeded when possible
				// @ts-ignore
				if (focusableElement.scrollIntoViewIfNeeded) {
					// @ts-ignore
					focusableElement.scrollIntoViewIfNeeded();
				}
			}
		}
	}

	setIsLoading(isLoading: boolean) {
		if (!this.model) {
			return;
		}
		this.model.isLoading = isLoading;
	}

	openModal(
		modalSettings: Partial<ModalSettings & { content: PropsWithChildren }>,
		event?: MouseEvent<unknown>,
	) {
		if (!this.model) {
			return;
		}
		// activeElement will not work in iOS
		const onCloseFocusElement = event?.target || document.activeElement || null;

		this.resetModal();
		this.alterModal({
			onCloseFocusElement,
			...modalSettings,
		});
		this.model.setIsOpen(true);

		if (typeof this.model.onOpenModal === 'function') {
			this.model.onOpenModal();
		}
	}

	resetModal() {
		if (!this.model) {
			return;
		}
		Object.assign(this.model, this.model.defaults);
	}

	scrollToTop() {
		if (!this.model) {
			return;
		}
		if (this.model.isOpen && isMobile) {
			scrollToTop();
		}
	}

	getClosestFocusableParent(element: EventTarget | HTMLElement | Element): EventTarget | Element | null {
		if (isFocusable(element)) {
			return element;
		}

		if ('parentElement' in element && element.parentElement !== null) {
			return this.getClosestFocusableParent(element.parentElement);
		}

		return null;
	}
}

export { MagicModalStore };
