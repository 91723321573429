import React from 'react';
import { observer } from 'mobx-react';

import { getAccessibleReactProps } from '~/util/formz/util/util';

const Input = observer(function Input(props) {
	const reactProps = getAccessibleReactProps(props);

	const {
		key,
		...inputProps
	} = reactProps;

	return <input key={key} {...inputProps} />;
});

export { Input };
